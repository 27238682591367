<template>
  <div>
    <v-row>
      <v-col cols="12" md="3">
        <v-card>
          <v-list shaped>
            <v-subheader>ROLES</v-subheader>

            <v-list-item-group color="primary">
              <v-list-item v-for="(role, i) in roles" :key="i" @click="editItem(role)">
                <v-list-item-title>{{ role.name }}</v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-col>

      <v-col cols="12" md="9">
        <v-card class="mb-4" v-show="showable">
          <v-card-text>
            <v-container>
              <h3>{{ editedItem.name }}</h3>
              <div v-html="roleDescriptions[editedItem.id]"></div>
            </v-container>
          </v-card-text>
        </v-card>

        <v-card v-show="showConfigurablePermissions">
          <v-card-text>
            <v-container>
              <v-row>
                <v-col md="4" cols="12">
                  <label>CONFIGURABLE PERMISSIONS </label>
                </v-col>

                <v-spacer></v-spacer>

                <v-col md="2" cols="12">
                  <v-btn class="mx-2" dark color="success" @click="saveItem()">
                    <v-icon left>mdi-content-save</v-icon>Save
                  </v-btn>
                </v-col>
              </v-row>

              <v-row v-show="editedItem.id == 9">
                <v-col cols="12" md="12">
                  <v-checkbox
                    label="Allow to reschedule / cancel trips"
                    v-model="roleConfig.approver.rescheduleTrips"
                    :value="roleConfig.approver.rescheduleTrips"
                    hide-details
                    class="mt-0"
                  ></v-checkbox>
                </v-col>
              </v-row>

              <v-row v-show="editedItem.id == 10">
                <v-col cols="12" md="12">
                  <v-checkbox
                    label="Allow to reschedule / cancel trips"
                    v-model="roleConfig.siteAdmin.rescheduleTrips"
                    :value="roleConfig.siteAdmin.rescheduleTrips"
                    hide-details
                    class="mt-0"
                  ></v-checkbox>
                </v-col>
              </v-row>

              <v-row v-show="editedItem.id == 13">
                <v-col cols="12" md="6">
                  <v-checkbox
                    label="Allow to change drivers"
                    v-model="roleConfig.schoolFinance.changeDrivers"
                    :value="roleConfig.schoolFinance.changeDrivers"
                    hide-details
                    class="mt-0"
                  ></v-checkbox>
                </v-col>

                <v-col cols="12" md="6">
                  <v-checkbox
                    label="Allow to enter mileage / time"
                    v-model="roleConfig.schoolFinance.enterMileage"
                    :value="roleConfig.schoolFinance.enterMileage"
                    hide-details
                    class="mt-0"
                  ></v-checkbox>
                </v-col>
              </v-row>

              <v-row v-show="editedItem.id == 14">
                <v-col cols="12" md="6">
                  <v-checkbox
                    label="Allow to fully manage invoices for their locations"
                    v-model="roleConfig.vehicleOwner.manageInvoices"
                    :value="roleConfig.vehicleOwner.manageInvoices"
                    hide-details
                    class="mt-0"
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" md="6">
                  <v-checkbox
                    label="Allow to select any vehicle location for vehicle assignment"
                    v-model="roleConfig.vehicleOwner.anyVehicleLocation"
                    :value="roleConfig.vehicleOwner.anyVehicleLocation"
                    hide-details
                    class="mt-0"
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" md="6">
                  <v-checkbox
                    label="Allow to see listing of available vehicles with the 'All Locations' button"
                    v-model="roleConfig.vehicleOwner.showAllLocations"
                    :disabled="roleConfig.vehicleOwner.assignAllLocations"
                    :value="roleConfig.vehicleOwner.showAllLocations"
                    hide-details
                    class="mt-0"
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" md="6">
                  <v-checkbox
                    label="Allow to assign vehicles from the 'All Locations' button"
                    v-model="roleConfig.vehicleOwner.assignAllLocations"
                    :value="roleConfig.vehicleOwner.assignAllLocations"
                    @change="
                      roleConfig.vehicleOwner.assignAllLocations
                        ? (roleConfig.vehicleOwner.showAllLocations = true)
                        : null
                    "
                    hide-details
                    class="mt-0"
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" md="6">
                  <v-checkbox
                    label="Allow to use vehicle override button when assigning vehicles"
                    v-model="roleConfig.vehicleOwner.overrideVehicle"
                    :value="roleConfig.vehicleOwner.overrideVehicle"
                    hide-details
                    class="mt-0"
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" md="6">
                  <v-checkbox
                    label="Allow to use driver override button when assigning drivers"
                    v-model="roleConfig.vehicleOwner.overrideDriver"
                    :value="roleConfig.vehicleOwner.overrideDriver"
                    hide-details
                    class="mt-0"
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" md="6">
                  <v-checkbox
                    label="Allow to reschedule / cancel trips"
                    v-model="roleConfig.vehicleOwner.rescheduleTrips"
                    :value="roleConfig.vehicleOwner.rescheduleTrips"
                    hide-details
                    class="mt-0"
                  ></v-checkbox>
                </v-col>
              </v-row>

              <v-row v-show="editedItem.id == 15">
                <v-col cols="12" md="6">
                  <v-checkbox
                    label="Allow to fully manage invoices for their locations"
                    v-model="roleConfig.specialNeedsVehicleOwner.manageInvoices"
                    :value="roleConfig.specialNeedsVehicleOwner.manageInvoices"
                    hide-details
                    class="mt-0"
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" md="6">
                  <v-checkbox
                    label="Allow to select any vehicle location for vehicle assignment"
                    v-model="roleConfig.specialNeedsVehicleOwner.anyVehicleLocation"
                    :value="roleConfig.specialNeedsVehicleOwner.anyVehicleLocation"
                    hide-details
                    class="mt-0"
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" md="6">
                  <v-checkbox
                    label="Allow to see listing of available vehicles with the 'All Locations' button"
                    v-model="roleConfig.specialNeedsVehicleOwner.showAllLocations"
                    :disabled="roleConfig.specialNeedsVehicleOwner.assignAllLocations"
                    :value="roleConfig.specialNeedsVehicleOwner.showAllLocations"
                    hide-details
                    class="mt-0"
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" md="6">
                  <v-checkbox
                    label="Allow to assign vehicles from the 'All Locations' button"
                    v-model="roleConfig.specialNeedsVehicleOwner.assignAllLocations"
                    :value="roleConfig.specialNeedsVehicleOwner.assignAllLocations"
                    @change="
                      roleConfig.specialNeedsVehicleOwner.assignAllLocations
                        ? (roleConfig.specialNeedsVehicleOwner.showAllLocations = true)
                        : null
                    "
                    hide-details
                    class="mt-0"
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" md="6">
                  <v-checkbox
                    label="Allow to use vehicle override button when assigning vehicles"
                    v-model="roleConfig.specialNeedsVehicleOwner.overrideVehicle"
                    :value="roleConfig.specialNeedsVehicleOwner.overrideVehicle"
                    hide-details
                    class="mt-0"
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" md="6">
                  <v-checkbox
                    label="Allow to use driver override button when assigning drivers"
                    v-model="roleConfig.specialNeedsVehicleOwner.overrideDriver"
                    :value="roleConfig.specialNeedsVehicleOwner.overrideDriver"
                    hide-details
                    class="mt-0"
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" md="6">
                  <v-checkbox
                    label="Allow to reschedule / cancel trips"
                    v-model="roleConfig.specialNeedsVehicleOwner.rescheduleTrips"
                    :value="roleConfig.specialNeedsVehicleOwner.rescheduleTrips"
                    hide-details
                    class="mt-0"
                  ></v-checkbox>
                </v-col>
              </v-row>

              <v-row v-show="editedItem.id == 16">
                <v-col cols="12" md="12">
                  <v-checkbox
                    label="Allow to view Trips indicating students have health concerns from ALL locations"
                    v-model="roleConfig.nurse.viewAllLocations"
                    :value="roleConfig.nurse.viewAllLocations"
                    hide-details
                    class="mt-0"
                  ></v-checkbox>
                </v-col>
              </v-row>

              <v-row v-show="editedItem.id == 19">
                <v-col cols="12" md="6">
                  <v-checkbox
                    label="Allow to reschedule / cancel trips"
                    v-model="roleConfig.requester.rescheduleTrips"
                    :value="roleConfig.requester.rescheduleTrips"
                    hide-details
                    class="mt-0"
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" md="6">
                  <v-checkbox
                    label="Allow to enter mileage / hours"
                    v-model="roleConfig.requester.enterMileage"
                    :value="roleConfig.requester.enterMileage"
                    hide-details
                    class="mt-0"
                  ></v-checkbox>
                </v-col>
              </v-row>

              <v-row v-show="editedItem.id == 5">
                <v-col cols="12" md="6"> </v-col>
                <v-col cols="12" md="6">
                  <v-checkbox
                    label="Allow to enter mileage / hours"
                    v-model="roleConfig.driver.enterMileage"
                    :value="roleConfig.driver.enterMileage"
                    hide-details
                    class="mt-0"
                  ></v-checkbox>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
        <v-card v-show="showable">
          <v-card-text>
            <v-container v-if="editedItem.id > 0">
              <v-row>
                <v-col>
                  <h3>Trip Request</h3>
                  <div>
                    <v-checkbox dense v-model="rolePermisssion.view.triprequest" label="View" readonly></v-checkbox>
                    <v-checkbox dense v-model="rolePermisssion.add.triprequest" label="Add" readonly></v-checkbox>
                    <v-checkbox dense v-model="rolePermisssion.edit.triprequest" label="Edit" readonly></v-checkbox>
                    <v-checkbox dense v-model="rolePermisssion.delete.triprequest" label="Delete" readonly></v-checkbox>
                  </div>
                </v-col>
                <v-col>
                  <h3>Assignment</h3>
                  <div>
                    <v-checkbox dense v-model="rolePermisssion.view.assignment" label="View" readonly></v-checkbox>
                    <v-checkbox dense v-model="rolePermisssion.add.assignment" label="Add" readonly></v-checkbox>
                    <v-checkbox dense v-model="rolePermisssion.edit.assignment" label="Edit" readonly></v-checkbox>
                    <v-checkbox dense v-model="rolePermisssion.delete.assignment" label="Delete" readonly></v-checkbox>
                  </div>
                </v-col>
                <v-col>
                  <h3>Invoice</h3>
                  <div>
                    <v-checkbox dense v-model="rolePermisssion.view.invoice" label="View" readonly></v-checkbox>
                    <v-checkbox dense v-model="isInvoiceEditable" label="Edit" readonly></v-checkbox>
                  </div>
                </v-col>
                <v-col>
                  <h3>Setup Tables</h3>
                  <div>
                    <v-checkbox dense v-model="rolePermisssion.view.tables" label="View" readonly></v-checkbox>
                    <v-checkbox dense v-model="rolePermisssion.add.tables" label="Add" readonly></v-checkbox>
                    <v-checkbox dense v-model="rolePermisssion.edit.tables" label="Edit" readonly></v-checkbox>
                    <v-checkbox dense v-model="rolePermisssion.delete.tables" label="Delete" readonly></v-checkbox>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <h3>Users</h3>
                  <div>
                    <v-checkbox dense v-model="rolePermisssion.view.user" label="View" readonly></v-checkbox>
                    <v-checkbox dense v-model="rolePermisssion.add.user" label="Add" readonly></v-checkbox>
                    <v-checkbox dense v-model="rolePermisssion.edit.user" label="Edit" readonly></v-checkbox>
                    <v-checkbox dense v-model="rolePermisssion.delete.user" label="Delete" readonly></v-checkbox>
                  </div>
                </v-col>
                <v-col>
                  <h3>Roles</h3>
                  <div>
                    <v-checkbox dense v-model="rolePermisssion.view.role" label="View" readonly></v-checkbox>
                    <v-checkbox dense v-model="rolePermisssion.edit.role" label="Edit" readonly></v-checkbox>
                  </div>
                </v-col>
                <v-col offset-md="3"></v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { GET_ROLES, SAVE_ROLE, DELETE_ROLE, GET_ALL_ROLES } from '@/store/modules/User/actions';
import { GET_CONFIG, SET_CONFIG } from '@/store/modules/Config/actions';
import roleDescriptions from '@/util/roleDescriptions.js';
import { ROLES } from '@/shared/common';

export default {
  name: 'Permissions',
  inject: ['eventHub'],
  components: {},
  data() {
    return {
      roleDescriptions,
      showable: false,
      editedItem: {},
      allowed: [],
      securedItems: {},
      roles: [],
      showPermissions: false,
      allUserPermissions: [],
      rolePermisssion: {},
      roleConfig: {
        approver: {
          rescheduleTrips: false,
        },
        siteAdmin: {
          rescheduleTrips: false,
        },
        schoolFinance: {
          changeDrivers: false,
          enterMileage: false,
        },
        vehicleOwner: {
          manageInvoices: false,
          anyVehicleLocation: false,
          showAllLocations: false,
          assignAllLocations: false,
          overrideVehicle: false,
          overrideDriver: false,
          rescheduleTrips: false,
        },
        specialNeedsVehicleOwner: {
          manageInvoices: false,
          anyVehicleLocation: false,
          showAllLocations: false,
          assignAllLocations: false,
          overrideVehicle: false,
          overrideDriver: false,
          rescheduleTrips: false,
        },
        nurse: {
          viewAllLocations: false,
        },
        requester: {
          rescheduleTrips: false,
          enterMileage: false,
        },
        driver: {
          enterMileage: false,
        },
      },
    };
  },
  mounted() {
    this.fetchItems();
  },
  computed: {
    ...mapGetters('user', ['me']),
    ...mapGetters('config', ['permissions']),
    showConfigurablePermissions() {
      return (
        this.editedItem.id == ROLES.APPROVER ||
        this.editedItem.id == ROLES.SITE_ADMIN ||
        this.editedItem.id == ROLES.SCHOOL_FINANCE ||
        this.editedItem.id == ROLES.VEHICLE_OWNER ||
        this.editedItem.id == ROLES.SPECIAL_NEEDS_VEHICLE_OWNER ||
        this.editedItem.id == ROLES.NURSE ||
        this.editedItem.id == ROLES.REQUESTER ||
        this.editedItem.id == ROLES.DRIVER
      );
    },
    isInvoiceEditable() {
      if (this.editedItem.id == ROLES.VEHICLE_OWNER) {
        return this.roleConfig.vehicleOwner.manageInvoices;
      } else if (this.editedItem.id == ROLES.SPECIAL_NEEDS_VEHICLE_OWNER) {
        return this.roleConfig.specialNeedsVehicleOwner.manageInvoices;
      }
      return this.rolePermisssion.edit.invoice;
    },
  },
  methods: {
    ...mapActions('user', [GET_ROLES, SAVE_ROLE, DELETE_ROLE, GET_ALL_ROLES]),
    ...mapActions('config', [GET_CONFIG, SET_CONFIG]),
    async fetchItems() {
      this.roles = await this.getRoles();
      const userPermissions = this.permissions ?? (await this.getConfig('permissions'));
      this.allUserPermissions = await this.getAllRoles();
      this.roleConfig = JSON.parse(JSON.stringify({ ...this.roleConfig, ...userPermissions }));
    },
    createItem() {
      this.editedItem = {};
      this.showable = true;
    },
    editItem(item) {
      this.editedItem = item;
      if (this.editedItem.id == 1) {
        const allTrue = {
          user: true,
          role: true,
          tables: true,
          triprequest: true,
          assignment: true,
          invoice: true,
        };
        this.rolePermisssion = {
          view: allTrue,
          add: allTrue,
          edit: allTrue,
          delete: allTrue,
        };
      } else {
        this.rolePermisssion = this.allUserPermissions.find(
          (permissionSet) => permissionSet.role == this.editedItem.id
        ).perms;
      }

      this.showable = true;
    },
    async saveItem() {
      try {
        const r = await this.setConfig({ name: 'permissions', value: this.roleConfig });
        if (r && r.done) {
          this.$myalert.success('Permissions saved');
          const item = this.editedItem;
          this.editedItem = {};
          this.editItem(item);
        }
      } catch (error) {
        this.$myalert.error(error.message);
      }
    },
    async deleteItem() {
      if (this.editedItem.id) {
        const ok = await this.$myconfirm('Are you sure you want to delete this Role?');
        if (ok) {
          try {
            const r = await this.deleteRole(this.editedItem.id);
            if (r.id) {
              this.$myalert.success('Role deleted');
              await this.fetchItems();
            }
          } catch (e) {
            this.$myalert.error(e.message);
          }
        }
      }
      this.editedItem = {};
      this.showable = false;
    },
  },
  watch: {
    editedItem() {
      if (this.editedItem.name === 'Vehicle Owner') {
        if (this.roleConfig.vehicleOwner.manageInvoices) {
          this.rolePermisssion.edit.invoice = true;
        }
      }
    },
  },
};
</script>

<style lang="scss">
.table-action {
  margin-left: 10px;
}
</style>
